import React, {  } from "react";
import Header from "./Header";
import classNames from "../helpers/classNames";
// import { isMobile } from "react-device-detect";
import Footer from "./Footer";

const AppLayout = ({ children }) => {
  // const showBtmNavigation = useMemo(() => {
  //   return isMobile;
  // }, []);

  return (
    <div
      className={classNames(
        "w-screen h-screen flex flex-col justify-start align-center overflow-y-auto overflow-x-hidden custom-scrollbar bg-white"
      )}
    >
      <Header />
      <main
        className={`relative flex-1 main-content   `}
        style={{ fontFamily: "Alvenir, sans-serif" }}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default AppLayout;
