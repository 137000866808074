import { useEffect, useState } from "react";

const useSupportedLayout = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return {
    isMobile: width < 768, 
    // isLayoutSupported: true,
  };
};

export default useSupportedLayout;