import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from '../../helpers/classNames';
import { ClipboardIcon, LockClosedIcon, LogoutIcon } from '@heroicons/react/outline';
import useWeb3Auth from '../../hooks/useWeb3Auth';
import { getMinimizeNumberStr, middleTruncate } from '../../helpers/TextFormatter';
import { preventAndStop } from '../../helpers/Generic';
import { toast } from 'react-toastify';
import Web3 from 'web3';
// import { BLOCKCHAIN } from '../../setupConfig';
// import LikhaCRUD from '../../helpers/LikhaCRUD';
import BYCAuthUserFunc from '../../helpers/BYCAuthUserFunc';
import NFTPreview from './NFTPreview';
import Spinner from '../loading/Spinner';
import useModal from '../../hooks/useModal';
import ContentModal from '../modal/ContentModal';
import useSupportedLayout from '../../hooks/useSupportedLayout';
import { MINTING_CONTRACT_ADDRESSES } from '../../setupConfig';

const BYCAuthAccountWallet = ({ wallet }) => {
  const { web3Account } = useWeb3Auth();
  const copyToClipboard = useCallback(async (ev) => {
    try {
      preventAndStop(ev);
      await navigator.clipboard.writeText(ev.currentTarget.id);
      toast.success('Wallet address copied to clipboard')
    } catch (err) {
      toast.error(err);
    }
  }, []);
  const walletIsActive = useMemo(() => wallet.Address === web3Account.toLowerCase(), [web3Account, wallet]);
  const { isMobile } = useSupportedLayout();


  return (
    <li data-active={walletIsActive}>
      <div className='flex flex-row justify-start items-center'>
        <div className={classNames('rounded-full h-4 w-4 mr-2', walletIsActive ? 'bg-green-300' : 'bg-slate-500')}/>
        {middleTruncate(wallet.Address, 6, isMobile ? 6 : 20)}
      </div>
      <button id={wallet.Address} onClick={copyToClipboard}>
        <ClipboardIcon className='w-4 h-4'/>
      </button>
    </li>
  );
}

const MINT_ADDRESSES_WITH_LOWERED = MINTING_CONTRACT_ADDRESSES.reduce((withLowered, mintAddress) => {
  return [
    ...withLowered,
    mintAddress,
    mintAddress.toLowerCase(),
  ]
}, []);

const BYCAuthAccount = ({ containerClassName = '' }) => {
  const { logout, web3Account, userAccount, web3AuthProvider, openLoginUserInfo } = useWeb3Auth();
  const [walletBalance, setWalletBalance] = useState('0');
  const [fetchingNFTs, setFetchingNFTs] = useState(false);
  useEffect(() => {
    const getBalance = async () => {
      const web3 = new Web3(web3AuthProvider);
      const balance = web3.utils.fromWei(await web3.eth.getBalance(web3Account));
      console.log(getMinimizeNumberStr(balance));
      setWalletBalance(getMinimizeNumberStr(balance));
    }
    getBalance();
  }, [web3Account, web3AuthProvider]);

  const [myNFTs, setMyNFTs] = useState([]);

  const getMyNFTs = useCallback(async(walletAddress) => {
    try {
      setFetchingNFTs(true);
      const fetchedNFTs = await BYCAuthUserFunc.post('getWalletNFTFromCollection', { WalletAddress: walletAddress, CollectionIDs: MINT_ADDRESSES_WITH_LOWERED });
      setMyNFTs(fetchedNFTs.data?.data?.nfts || []);
      setFetchingNFTs(false);
    } catch(err) {
      console.error('FETCH NFTS', err);
    }
  }, []);

  useEffect(() => {
    if(web3Account) {
      getMyNFTs(web3Account);
    } else {
      setMyNFTs([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Account]);

  const {
    isOpen: isPKOpen,
    closeModal: closePKModal,
    openModal: openPKModal,
  } = useModal();
  const {
    isOpen: isPKConfirmOpen,
    closeModal: closePKConfirmModal,
    openModal: openPKConfirmModal,
  } = useModal();

  const [privateKey, setPrivateKey] = useState('');

  const handleRetrievePK = useCallback(async (authProv, userInf) => {
    console.log('retrieve PK');
    console.log(authProv, userInf);
    if(authProv) {
      console.log('YES');
      if(!userInf?.verifier) {
        console.log('ACTUALL NO');
        setPrivateKey('');
        return;
      }
      console.log('PROCEED');
      try {

        const walletPK = await authProv.request({
          method: "eth_private_key"
        });
        console.log('walletPK', walletPK);
        setPrivateKey(walletPK);
      } catch(err) {
        console.log('PK RETRIEVE', err);
      }
    } else {
      console.log('WALANG USER');
      setPrivateKey('');
    }
  }, []);

  useEffect(() => {
    handleRetrievePK(web3AuthProvider, openLoginUserInfo)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3AuthProvider, openLoginUserInfo]);

  const handleCopyPK = useCallback(async () => {
    await navigator.clipboard.writeText(privateKey);
    toast.success('Wallet private key copied to clipboard');
  }, [privateKey]);

  const [yourWalletAddress, setYourWalletAddress] = useState('');

  const handleChangeWalletAddress = useCallback((ev) => setYourWalletAddress(ev.target.value), []);

  const handleConfirmShowPK = useCallback(() => {
    if(web3Account.toLowerCase() !== yourWalletAddress.toLowerCase()) {
      toast.error('Invalid wallet address')
      return;
    }

    closePKConfirmModal();
    openPKModal();
    setYourWalletAddress('');
  }, [web3Account, yourWalletAddress, closePKConfirmModal, openPKModal]);

  return (
    <div className={classNames("bg-white shadow-md sm:rounded-lg px-8 pt-6 pb-8 mb-4 flex flex-col w-full", containerClassName)}>
      <div className='flex flex-row justify-between items-center mb-4'>
        {/* <div
          className='bg-banner bg-contain bg-no-repeat min-w-[150px] max-w-[250px] w-full h-auto aspect-[350/76] mb-6 md:mb-0'
        /> */}
        <h1 className='text-2xl font-bold tracking-wider'>
          BYCAuth
        </h1>
        {/* <div
          className='min-w-[150px] max-w-[250px] w-full h-full mb-6 md:mb-0'
        >
        </div> */}
        <button className='hover:bg-red-500 p-2 rounded-full group' onClick={logout}>
          <LogoutIcon className='h-4 w-4 group-hover:stroke-white'/>
        </button>
      </div>
      <hr className='w-full'/>
      <div className='flex flex-col justify-center items-center p-4 relative'>
        <div className='byc-auth-acc-wallets'>
          <div className='flex flex-row justify-between w-full'>
            <h3 className='text-xl font-semibold mb-4'>Your Wallet</h3>
            <h3 className='text-xl font-semibold mb-4'>{walletBalance} MATIC</h3>
          </div>
          <ul className='ml-2 w-full'>
          {
            userAccount.ConnectedWallets?.map((connectedWallet) => {
              console.log('web3Account', web3Account);
              console.log('connectedWallet.Address', connectedWallet.Address);

              return (
                <BYCAuthAccountWallet key={connectedWallet.Address} wallet={connectedWallet}/>
              )
            })
          }
          </ul>
          {
            openLoginUserInfo?.verifier ?
            <button className='rounded-md text-red-500 border border-red-500 border-solid mt-8 self px-6 py-2 flex flex-row justify-start items-center' onClick={openPKConfirmModal}>
              View Private Key
              <LockClosedIcon className='stroke-red-500 w-6 h-6 ml-4'/>
            </button> : null
          }
        </div>
        <div className='byc-auth-acc-nfts'>
          <h3 className='text-xl font-semibold mb-4'>Your PBW NFTs</h3>
          <div className={
            classNames(
              "flex flex-wrap justify-center items-center",
              myNFTs.length > 0 ? "!justify-start !items-start" : ""
            )
          }>
          {
            fetchingNFTs ? <Spinner/> :
            myNFTs.length ? 
            myNFTs.map((nft) => {

              return (
                <NFTPreview nft={nft} key={nft.id}/>
              )
            }) :
            <p>You don't have PBW NFTs yet</p>
          }
          </div>
        </div>
      </div>
      <ContentModal isOpen={isPKConfirmOpen && !!web3Account} onClose={closePKConfirmModal} dialogClassName="!p-0">
        <div className='w-full h-full flex flex-col justify-start items-center p-10 '>
          <label>Please input your wallet address</label>
          <input type='text' value={yourWalletAddress} onChange={handleChangeWalletAddress} className='rounded-md w-full mt-4'/>
          <p className='bg-red-500 text-white font-semibold mt-4 p-2 rounded-md'>
            Warning: Exporting your wallet's private key is extremely risky and could lead to the loss of all your digital assets. Please do not share or export your private key to anyone, including third-party services or individuals. Keep your private key safe and secure at all times.
          </p>
          <button onClick={handleConfirmShowPK} className='text-red-500 border border-red-500 px-6 py-2 rounded-md mt-10'>
            Confirm Wallet Address
          </button>
        </div>
      </ContentModal>
      <ContentModal isOpen={isPKOpen && !!web3Account} onClose={closePKModal} dialogClassName="!p-0">
        <div className='w-full h-full flex flex-col justify-start items-center p-10 '>
          <p className='text-red-500 w-full break-words text-center'>
            {privateKey}
          </p>
          <button onClick={handleCopyPK} className='text-red-500 border border-red-500 px-6 py-2 rounded-md mt-10'>
            Copy Private Key
          </button>
        </div>
      </ContentModal>
    </div>
  );
};

export default BYCAuthAccount;