const NavigationList = [
  // {
  //   link: "/",
  //   label: "Home",
  // },
  // {
  //   link: "artist",
  //   label: "Buy Ticket",
  // },
  
];

export default NavigationList;
