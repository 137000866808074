import React from 'react';
import MintedNFTArt from '../../pages/Home/components/MintedNFTArt';

const NFTPreview = ({ nft }) => {

  return (
    <a className='nft-preview' href={`${process.env.REACT_APP_LIKHA_MP_URL}view-nft/EVM/0x89/${nft.contract_address}/${nft.tokenID}`} target='_blank' rel="noreferrer" >
      {/* <img 
        src={nft.likha_nft_image}
        alt='Minted NFT'
      /> */}
      <MintedNFTArt 
        artSrc={nft?.likha_nft_image}
      />
      <text className='nft-name'>
      {nft.name}
      </text>
    </a>
  );
};

export default NFTPreview;