import { createSlice } from '@reduxjs/toolkit'
import { Cookies } from 'react-cookie';
import COOKIE_NAMES from '../../data/cookies';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    loggingIn: false,
    authenticatingToken: new Cookies().get(COOKIE_NAMES.TOKEN),
  },
  reducers: {
    successLogin: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.currentUser = action.payload.user;
      state.loggingIn = false;
    },
    logoutUser: (state) => {
      state.currentUser = null;
    },
    setAuthenticatingToken: (state, action) => {
      state.authenticatingToken = action.payload.isAuthenticating;
    },
  },
})

// Action creators are generated for each case reducer function
export const { successLogin, logoutUser, setAuthenticatingToken } = authSlice.actions;

export default authSlice.reducer