import { format } from "date-fns";
import { DISCOUNT_DESCRIPTION } from "../data/texts";

export const convertWeiTo = ({ price = 0, priceConversion = 1}) => {
  const maticValue = (price ?? 0) / Math.pow(10, 18);
  return (maticValue * priceConversion).toFixed(4);
}

export const middleTruncate = (str, leftLength = 4, rightLength = 4) => {
  if (str.length > (leftLength + rightLength)) {
    return str.substr(0, leftLength) + '...' + str.substr(str.length-rightLength);
  }
  return str;
}

export const getPromoDescription = ({ repeat, daily, discount }) => {
  let description = '';
  if(repeat === 'ONCE') {
    if(daily) {
      description = 'Everyday'
    } else {
      description = 'One time'
    }
  } else if(repeat === 'BIRTHDAY') {
    description = 'Once a Year'
  } else if(typeof repeat === 'boolean' && repeat) {
    if(typeof daily === 'object') {
      if(daily.time) {
        let timeBound = [];
        if(daily.time.start) {
          timeBound.push(daily.time.start);
        }
        if(daily.time.end) {
          timeBound.push(daily.time.end);
        }
        description = `Daily starting ${timeBound.join('up to')}`;
      }
    }
  }

  return {
    description,
    discount: typeof discount === 'number' ? discount === 1 ? 'FREE' : `${discount*100}% Off` : DISCOUNT_DESCRIPTION[discount],
  }
}

export const getReadableDateTime = (date, noDate = 'Invalid Date') => {
  let dateStr = noDate;
  if(date) {
    if(Object.prototype.toString.call(date) === '[object Date]') {
      dateStr = format(new Date(date), 'PPpp');
    } else if(date.startDate && date.endDate) {
      dateStr = `${format(Date.parse(date?.startDate), 'PPpp')} - ${format(Date.parse(date?.endDate), 'PPpp')}`;
    } else if(date.startDate) {
      dateStr = `Starting from: ${format(Date.parse(date?.startDate), 'PPpp')}`
    } else if(date.endDate) {
      dateStr = `Until: ${format(Date.parse(date?.endDate), 'PPpp')}`
    } else {
      dateStr = noDate;
    }
  }
  return dateStr;
}

export const getReadableDate = (date, noDate = 'Invalid Date') => {
  let dateStr = noDate;
  if(date) {
    if(Object.prototype.toString.call(date) === '[object Date]') {
      dateStr = format(new Date(date), 'PP');
    } else if(date.startDate && date.endDate) {
      dateStr = `${format(Date.parse(date?.startDate), 'PP')} - ${format(Date.parse(date?.endDate), 'PP')}`;
    } else if(date.startDate) {
      dateStr = `Starting from: ${format(Date.parse(date?.startDate), 'PP')}`
    } else if(date.endDate) {
      dateStr = `Until: ${format(Date.parse(date?.endDate), 'PP')}`
    } else {
      dateStr = noDate;
    }
  }
  return dateStr;
}

export const getReadableTime = (date, noTime = 'Invalid Time') => {
  let dateStr = noTime;
  if(date) {
    if(Object.prototype.toString.call(date) === '[object Date]') {
      dateStr = format(new Date(date), 'HH:mm a');
    } else if(date.startDate && date.endDate) {
      dateStr = `${format(Date.parse(date?.startDate), 'HH:mm a')} - ${format(Date.parse(date?.endDate), 'HH:mm a')}`;
    } else if(date.startDate) {
      dateStr = `Starting from: ${format(Date.parse(date?.startDate), 'HH:mm a')}`
    } else if(date.endDate) {
      dateStr = `Until: ${format(Date.parse(date?.endDate), 'HH:mm a')}`
    } else {
      dateStr = noTime;
    }
  }
  return dateStr;
}

export const getMinimizeNumberStr = (number, maxDecimal = 4) => {
  console.log(JSON.stringify({ type: typeof number, value: number }));
  let numberStr = number;
  if(typeof number === 'number') {
    numberStr = number.toFixed(maxDecimal);
  } else if(typeof number === 'string') {
    if(!number) return '0';
    numberStr = number.slice(0, number.indexOf('.') + maxDecimal + 1);
  } else {
    return 'not a number';
  }
  while(numberStr.includes('.') && numberStr.charAt(numberStr.length - 1) === '0') {
    numberStr = numberStr.slice(0, -1);
  }
  
  if(numberStr.endsWith('.')) numberStr = numberStr.slice(0, -1);

  console.log('numberStr', numberStr, 'maxDecimal', maxDecimal);

  return numberStr;
}

export const REGEX_PATTERNS = {
  EMAIL: /^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^-]+(?:\\.[a-zA-Z0-9_!#$%&’*+/=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$/,
  MOBILE: /^9\d{9}$/,
}

const VIDEO_FILE_EXT = [
  '.ogm',
  '.wmv',
  '.mpg',
  '.webm',
  '.ogv',
  '.mov',
  '.asx',
  '.mpeg',
  '.mp4',
  '.m4v',
  '.avi',
]

export const isImageURL = (src) => {
  const loweredSrc = src.toLowerCase();
  return VIDEO_FILE_EXT.reduce((yes, extension) => {
    if(yes) {
      return !loweredSrc.endsWith(extension);
    }
    return yes;
  }, true);
}