import React from 'react';
import useModal from '../../hooks/useModal';
import useWeb3Auth from '../../hooks/useWeb3Auth';
import BYCAuthAccount from '../byc-auth';
import ContentModal from '../modal/ContentModal';
import Web3AuthLogin from '../web3auth/Web3AuthLogin';

const BYCAuthButton = () => {
  const { isOpen, closeModal, openModal } = useModal();
  const { userAccount, isRetrievingBYCAuth } = useWeb3Auth();

  console.log('userAccount', userAccount);

  return (
    <>
      <button onClick={openModal} className='text-[12px] tablet:text-[16px]'>
      { userAccount  ? 'Connected' : isRetrievingBYCAuth ? 'Connecting' : 'Connect' }
      </button>
      <ContentModal isOpen={isOpen} onClose={closeModal} dialogClassName='!p-0 relative'>
      {
        userAccount ? 
        <BYCAuthAccount containerClassName='!mb-0'/> :
        <Web3AuthLogin containerClassName='!mb-0'/>
      }
      </ContentModal>
    </>
  );
};

export default BYCAuthButton;