import './typedef';
import { ReactComponent as EthereumSVG } from './assets/svg/networks/ethereum.svg';
import { ReactComponent as PolygonSVG } from './assets/svg/networks/polygon.svg';
export const BLOCKCHAIN_MODE = process.env.REACT_APP_BLOCKCHAIN_MODE === 'PROD' ? 'PROD' : 'TEST';

// const PRE_WL_ADDRESSES_STR = process.env.REACT_APP_PRE_WL_ADDRESSES || '';
// export const PRE_WL_ADDRESSES = PRE_WL_ADDRESSES_STR.split(',').map((address) => address.toLowerCase());
export const PRE_WL_ADDRESSES = [];

export const LIKHA_CRUD_API = process.env.REACT_APP_LIKHA_CRUD_API || "https://likhafuncapp.azurewebsites.net";

const CHAIN_IDS = {
  PROD: [137, 1],
  TEST: [80001, 5],
};

const SUPPORTED_NETWORKS = {
  TEST: [
    {
      id: 5,
      name: 'Goerli (Eth Testnet)',
      hex: '0x5',
      rpc: 'https://goerli.infura.io/v3/',
      icon: EthereumSVG,
    },
    {
      id: 80001,
      name: 'Mumbai (Polygon Testnet)',
      hex: '0x13881',
      rpc: 'https://polygon-mumbai.infura.io/v3/',
      icon: PolygonSVG,
    },
  ],
  PROD: [
    {
      id: 1,
      name: 'Ethereum',
      hex: '0x5',
      rpc: 'https://mainnet.infura.io/v3/',
      icon: EthereumSVG,
    },
    {
      id: 137,
      name: 'Mumbai',
      hex: '0x13881',
      rpc: 'https://polygon-mainnet.infura.io/v3/',
      icon: PolygonSVG,
    },
  ]
}

export const NETWORK_HEX_IDS = {
  1: '0x1',
  4: '0x4',
  5: '0x5',
  80001: '0x13881',
  137: '0x89'
}

export const CHAIN_NETWORK_NAMES = {
  1: 'Ethereum',
  4: 'Rinkeby (Eth Testnet)',
  5: 'Goerli (Eth Testnet)',
  137: 'Polygon',
  80001: 'Polygon (Testnet)',
}
const TestMinterJSON = require(`./web3/jsons/test.json`);
const ProdMinterJSON = require(`./web3/jsons/prod.json`);

const BLOCKCHAIN_CONFIGS = {
  PROD: {
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.PROD,
    NETWORK: 'mainnet',
    POLYGONSCAN_URL: "https://polygonscan.com/",
    RPC_TARGET: "https://polygon-mainnet.infura.io/v3/",
    CHAIN_NAME: 'Polygon',
    INFURA_API_KEY: process.env.REACT_APP_INFURA_API_KEY,
    MINTER: {
      ADDRESS: "0x9A614b62fc19383C904E5906bAFa1139961c0e97",
      ARTIFACT: ProdMinterJSON,
    }
  },
  TEST: {
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.TEST,
    NETWORK: 'testnet',
    POLYGONSCAN_URL: "https://mumbai.polygonscan.com/",
    RPC_TARGET: "https://polygon-mumbai.infura.io/v3/",
    CHAIN_NAME: 'Mumbai (Testnet)',
    INFURA_API_KEY: process.env.REACT_APP_INFURA_API_KEY,
    MINTER: {
      ADDRESS: "0x720f2048B2489a5BF37ac5B64f024c8d19a66473",
      ARTIFACT: TestMinterJSON,
    }
  }
};

export const BYC_AUTH_API = process.env.REACT_APP_BYC_AUTH_API; 
export const WEB3_AUTH = {
  CLIENT_ID: process.env.REACT_APP_WEB3_AUTH_ID,
};


export const BLOCKCHAIN = BLOCKCHAIN_CONFIGS[BLOCKCHAIN_MODE];
export const NETWORKS = SUPPORTED_NETWORKS[BLOCKCHAIN_MODE]
export const WEB3AUTH_NETWORK_TYPE = BLOCKCHAIN_MODE === 'PROD' ? 'mainnet' : 'testnet';


export const MINTING_CONTRACT_ADDRESSES = [
  "0xA536C2bAD5498EcbCEF5386455D0491c851A92da", 
  "0x391dcdF321784b4206A4221627B163e7771a3593", 
  "0x6aF923cbE5c053BcD4c5bBa891065CD62Ea4B89A", 
  "0xc46AC095E0DE0142009c6088AaEfd675aB847505"
];

export const PBW_PERKS_LINK = "https://bit.ly/PBW_Digital_Pass_Guide";