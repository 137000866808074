import React, { useMemo } from "react";
import classNames from "../../../helpers/classNames";
import { isImageURL } from "../../../helpers/TextFormatter";


const MintedNFTArt = ({ artSrc = '', className = ''}) => {

  const isNFTImage = useMemo(() => {
    return isImageURL(artSrc);
  }, [artSrc]);

  return isNFTImage ?
  <img src={artSrc} alt={artSrc} className={classNames('w-56 aspect-square', className)}/> :
  <video
    src={artSrc} 
    autoPlay
    muted
    controls
    loop
    className={classNames('w-56 aspect-square', className)}
  />
}

export default MintedNFTArt;