import { useContext } from "react";
import { Web3Context } from "../contexts/Web3Context";

/**
 * @description fully utilized web3 and web3auth capabilities
 * 
 * 
 * @callback connectEmailCallback
 * @param {import('react').MouseEvent} ev
 * @param {string} email
 * 
 * @callback connectSocialCallback
 * @param {import('react').MouseEvent} ev
 * 
 * @callback connectWalletCallback
 * @param {import('react').MouseEvent} ev
 * 
 * @callback toggleSelectedNetworksCallback
 * @param {number} networkID
 * 
 * @typedef {Object} ConnectedWalletProvider
 * @property {string} id
 * @property {string} Name
 * @property {Object} Data
 * 
 * @typedef {Object} ConnectedWallet
 * @property {string} Address
 * @property {ConnectedWalletProvider} Provider
 * 
 * 
 * @typedef {Object} Web3Object
 * @property {boolean} isInitializing
 * @property {boolean} isConnecting
 * @property {boolean} isRetrievingBYCAuth
 * @property {connectEmailCallback} handleConnectEmail
 * @property {connectSocialCallback} handleConnectSocial
 * @property {connectWalletCallback} handleConnectWallet
 * @property {import('@web3auth/base').SafeEventEmitterProvider} web3AuthProvider
 * @property {import('@web3auth/base').UserInfo} openLoginUserInfo
 * @property {Object} userAccount
 * @property {function} disconnect
 * @property {string} web3Account
 * @property {string} connectionState
 * @property {Array<ConnectedWallet>} connectedWallets
 * @property {Array<number>} selectedNetworks
 * @property {toggleSelectedNetworksCallback} toggleSelectedNetworks
 * 
 * @return {Web3Object}
 * 
 */
const useWeb3Auth = () => {
  const context = useContext(Web3Context);

  return context; 
};

export default useWeb3Auth;