import React, { useCallback, useMemo } from 'react'
// import CustomButton from '../buttons/CustomButton';
// import FieldInput from '../forms/FieldInput';
import useWeb3Auth from '../../hooks/useWeb3Auth';
import { HAS_ETH_OBJ, SOCMED_CONFIGS, SOCMED_LOGIN, WALLET_CONFIGS, WALLET_LOGIN } from '../../contexts/Web3Context';
import Spinner from '../loading/Spinner';
import BridgepassLogo from '../../assets/img/Icon/bridgepass.png'
import classNames from '../../helpers/classNames';
import ContentModal from '../modal/ContentModal';
import { useCookies } from 'react-cookie';
import COOKIE_NAMES from '../../data/cookies';
import useModal from '../../hooks/useModal';
import('../../typedef');
const BYC_AUTH_GUIDE_IMG_URL = process.env.REACT_APP_BYC_AUTH_GUIDE_IMG_URL;


/**
 * 
 * @typedef {Object} SocmedProps 
 * @property {ALLOWED_SOCMEDS} socmed
 * 
 * @param {SocmedProps} param0
 * 
 */
const SocmedButton = ({ socmed, onConnect }) => {
  const socmedObject = useMemo(() => SOCMED_CONFIGS[socmed], [socmed]);

  return (
    <button
      className='rounded-full w-12 h-auto aspect-square transition-all mx-4 my-4 hover:-translate-y-4'
      onClick={onConnect}
      id={socmed}
    >
      {socmedObject.icon()}
    </button>
  )
};

/**
 * 
 * @typedef {Object} WalletBtnProps 
 * @property {ALLOWED_WALLETS} socmed
 * 
 * @param {WalletBtnProps} param0
 * 
 */
const WalletButton = ({ wallet, onConnect }) => {
  const walletObject = useMemo(() => WALLET_CONFIGS[wallet], [wallet]);

  return (
    <button 
      className='rounded-full w-14 h-auto aspect-square transition-all p-2 hover:-translate-y-4  hover:shadow-lg'
      onClick={onConnect}
      id={walletObject.walletName}
    >
      {walletObject.icon()}
    </button>
  )
};

// const EmailPasswordless = ({ onConnect }) => {
//   const [email, setEmail] = useState('');
//   const handleChangeEmail = useCallback((_, value) => setEmail(value), []);

//   const handleSubmitEmail = useCallback(async (ev) => {
//     onConnect(ev, email);
//   }, [email, onConnect]);
  
//   return (
//     <form className='flex flex-col w-full my-4' onSubmit={handleSubmitEmail}>
//       <FieldInput
//         label='Email'
//         name='Email'
//         type='email'
//         placeholder='juan.delacruz@rwards.xyz'
//         labelClassName='w-full text-center text-lg  text-rward-yellow'
//         value={email}
//         onChange={handleChangeEmail}
//         required
//       />
//       <CustomButton
//         text='Continue with Email'
//         btnClassName='bg-rward-blue-dark'
//       />
//     </form>
//   )
// }

const Web3AuthLogin = ({ containerClassName = ''}) => {
  const { 
    isInitializing, 
    isConnecting,
    isRetrievingBYCAuth,
    // handleConnectEmail,
    handleConnectSocial,
    handleConnectWallet,
    connectionState,
  } = useWeb3Auth();
  const [, setCookies] = useCookies(COOKIE_NAMES.BYC_AUTH_WALLET_GUIDE);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { 
    isOpen: isWalletGuideOpen, 
    // openModal: openWalletGuideModal, 
    closeModal: closeWalletGuideModal 
  } = useModal();

  // useEffect(() => {
  //   if(!cookies[COOKIE_NAMES.BYC_AUTH_WALLET_GUIDE]) {
  //     openWalletGuideModal();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  const dontShowGuideAgain = useCallback(() => {
    closeWalletGuideModal();
    setCookies(COOKIE_NAMES.BYC_AUTH_WALLET_GUIDE, true);
  }, [closeWalletGuideModal, setCookies]);

  return (
    <div className={classNames("bg-white shadow-md sm:rounded-lg px-8 pt-6 pb-8 mb-4 flex flex-col w-full", containerClassName)}>
      <div className='flex flex-col md:flex-row justify-center md:justify-between items-center md:mb-4'>
        {/* <div
          className='bg-banner bg-contain bg-no-repeat min-w-[150px] max-w-[250px] w-full h-auto aspect-[350/76] mb-6 md:mb-0'
        /> */}
        <div
          className='min-w-[150px] max-w-[250px] w-full h-full mb-6 md:mb-0'
        >
          <h1 className='text-2xl font-bold tracking-wider'>
            <img src={BridgepassLogo} alt='BYCAuth' className='h-36 w-auto mx-auto'/>
          </h1>
        </div>
        <h1 className='text-xl text-rward-yellow mb-4 md:mb-0 md:mx-4'>
          Sign in
        </h1>
      </div>
      <hr className='w-full'/>
      <div className='flex flex-col justify-center items-center p-4 relative'>
        <h2 className='text-lg text-rward-yellow'>Continue with</h2>
        <div className='flex flex-row justify-center my-4'>
        {
          SOCMED_LOGIN.map((socmed) => {

            return (
              <SocmedButton 
                key={socmed}
                socmed={socmed}
                onConnect={handleConnectSocial}
              />
            );
          })
        }
        </div>
        {/* <EmailPasswordless onConnect={handleConnectEmail}/> */}
        {
          HAS_ETH_OBJ ? 
          <div className='flex flex-col w-full my-4 justify-center items-center'>
            <h2 className='text-lg text-rward-yellow'>External Wallet</h2>
            <div className='flex flex-row justify-center my-4'>
            {
              WALLET_LOGIN.map((wallet) => {

                return (
                  <WalletButton 
                    key={wallet}
                    wallet={wallet}
                    onConnect={handleConnectWallet}
                  />
                );
              })
            }
            </div>
          </div> : null
        }
        {
          isInitializing || isConnecting || isRetrievingBYCAuth ? 
          <div className='web3auth-initializing'>
            <Spinner/>
            <span className='mt-4 text-xl'>{connectionState}</span>
          </div> : null
        }
      </div>
      {/* {
        !isConnecting && !isInitializing && !isRetrievingBYCAuth &&
        <button 
          className='absolute bottom-8 right-8 rounded-full bg-slightDarkBlue py-2 px-4 text-white'
          onClick={openWalletGuideModal}
        >
          Help
        </button>
      } */}
      <ContentModal isOpen={isWalletGuideOpen} onClose={closeWalletGuideModal} dialogClassName='!max-w-none'>
        <div className='w-full flex flex-col justify-start items-center'>
          <h2 className='text-lg font-semibold'>PreOrder Guide</h2>
          <hr className='w-full h-1 bg-slate-500 border-none'/>
          <img src={BYC_AUTH_GUIDE_IMG_URL} className='w-24 h-24 my-8' alt='BYCAuth Wallet Guide'/>
          <hr className='w-full h-1 bg-slate-500 border-none'/>
          <button onClick={dontShowGuideAgain}>
            Understood
          </button>
        </div>
      </ContentModal>
    </div>
  );
};

export default Web3AuthLogin;