import React from "react";
export default function Footer() {

  return (
    <footer className="">
      <div
        className="bg-no-repeat bg-black w-full flex flex-row justify-end items-center h-[30px] tablet:h-[60px]  text-white text-[10px] tablet:text-[16px] font-bold"
        style={{ fontFamily: "Alvenir-Black, sans-serif" }}
      >
        <div className="mr-5 tracking-wider">
          Terms and Conditions | Powered by Rwards and Bayanichain | PBW 2023
        </div>
      </div>
    </footer>
  );
}
