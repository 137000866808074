/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { HashLink } from "react-router-hash-link";
// import { Popover, Transition } from "@headlessui/react";
// import Likha from "../assets/img/KeyPartner/Likha.png";
import BYCAuthButton from "../components/buttons/BYCAuthButton";
import NavigationList from "../constants/NavigationList";
// import classNames from "../helpers/classNames";
// import { MenuAlt3Icon, MenuIcon } from "@heroicons/react/outline";
import PBWLOGO2023 from "../assets/img/PBWLOGO2023.png";
import useSupportedLayout from "../hooks/useSupportedLayout";
const NavigationListRender = NavigationList.map((navigation) => (
  <HashLink to={`/#${navigation.link}`} key={navigation.link} smooth>
    {navigation.label}
  </HashLink>
));

// const NavigationListPopup = ({ open, close }) => {
//   return (
//     <>
//       <Popover.Button className="relative">
//         {open ? (
//           <MenuAlt3Icon className="h-8 w-8" aria-hidden="true" />
//         ) : (
//           <MenuIcon className={"h-8 w-8"} aria-hidden="true" />
//         )}
//       </Popover.Button>
//       <Transition
//         as={React.Fragment}
//         enter="transition ease-out duration-200"
//         enterFrom="opacity-0 translate-y-1"
//         enterTo="opacity-100 translate-y-0"
//         leave="transition ease-in duration-150"
//         leaveFrom="opacity-100 translate-y-0"
//         leaveTo="opacity-0 translate-y-1"
//       >
//         <Popover.Panel className="absolute z-10 mt-3 top-10 transform px-2 sm:px-0 left-16 -translate-x-full w-44">
//           <div className="rounded-lg shadow-lg overflow-hidden">
//             <div className="bg-black text-white px-5 py-6 flex flex-col space-y-2">
//               {NavigationListRender}
//             </div>
//           </div>
//         </Popover.Panel>
//       </Transition>
//     </>
//   );
// };

// const NavigationListButton = () => {
//   return (
//     <Popover className="ml-4 relative flex items-center md:hidden">
//       {(props) => <NavigationListPopup {...props} />}
//     </Popover>
//     // <div className='flex md:hidden'>
//     //   {NavigationListRender}
//     // </div>
//   );
// };

export default function Header() {
  const { isMobile } = useSupportedLayout();

  return (
    <div className="sticky top-0 bg-black text-white z-[100] tracking-wider">
      <div className="flex flex-row justify-between items-center  h-[60px] tablet:h-[90px] pl-[20px] pr-[15px] tablet:pr-[4%] tablet:pl-[10%] laptop:pr-[10%] laptop:pl-[15%]">
        <HashLink to={"/#home"} className="" smooth>
          <img
            src={PBWLOGO2023}
            alt={"PBW 2023"}
            className="h-[35px] tablet:h-[55px]"
          />{" "}
        </HashLink>
        <nav className="tablet:w-[400px] flex justify-evenly items-center text-xl tablet:text-[16px]">
          <div className="hidden md:flex flex-1 justify-evenly">
            {NavigationListRender}
            <BYCAuthButton />
          </div>
          {/* <BYCAuthButton />
           */}
          {isMobile && <BYCAuthButton />}
          {/* <NavigationListButton /> */}
        </nav>
      </div>
    </div>
  );
}
